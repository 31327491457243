import React, { Suspense, useState, useContext, useEffect } from "react";
import AppRouter from "./AppRouter";
import { Route, Routes } from "react-router-dom";
import { Context } from "../../context/store";
import ModalCover from "../../../general/login/ModalCover";
import { accountsConfig } from "../../helpers/axiosConfig";
import PageLoader from "../../includes/PageLoader";
import PrivateRoute from "../routes/PrivateRoute";
import AuthRouter from "./AuthRouter";
import AuthRoute from "../routes/AuthRoute";
import styled from "styled-components";

function MainRouter() {
    const {
        dispatch,
        state: { user_data },
    } = useContext(Context);
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        async function fetchUserData() {
            let promise = new Promise((resolve, reject) => {
                let user_data = localStorage.getItem("user_data");
                user_data = JSON.parse(user_data);
                dispatch({ type: "UPDATE_USER_DATA", payload: user_data });
                setTimeout(() => {
                    resolve("done!");
                    setLoading(false);
                }, 500);
            });

            let result = await promise;
        }
        fetchUserData();

        async function fetchUserValidate() {
            let promise = new Promise((resolve, reject) => {
                let user_validate = localStorage.getItem("user_validate");
                user_validate = JSON.parse(user_validate);
                dispatch({
                    type: "USER_CHECK_VALIDATE",
                    user_validate: user_validate,
                });
                setTimeout(() => {
                    resolve("done!");
                    setLoading(false);
                }, 500);
            });

            let result = await promise;
        }
        fetchUserValidate();
    }, []);

    useEffect(() => {
        const current_topic = localStorage.getItem("current_topic");
        if (current_topic) {
            dispatch({
                type: "UPDATE_CURRENT_VIDEO",
                currentTopic: current_topic,
            });
        }
    }, []);
    return !isLoading ? (
        <>
            <Suspense
            fallback={
                <Cover>
                    <PageLoader />
                </Cover>
            }
        >
            <Routes>
                <Route
                    path="auth/*"
                    element={
                        <AuthRoute>
                            <AuthRouter />
                        </AuthRoute>
                    }
                />
                <Route
                    path="/*"
                    element={
                        <PrivateRoute>
                            <AppRouter />
                        </PrivateRoute>
                    }
                />
            </Routes>
        </Suspense>
        </>
    ) : (
        <PageLoader />
    );
}

export default MainRouter;
const Cover = styled.div`
    height: 100vh;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
`;
