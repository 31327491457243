import React, { useContext, useEffect, useState, lazy } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { Context } from "../../../context/store";
import PrivateRoute from "../../../routing/routes/PrivateRoute";
import LandingPage from "../../../web/screens/LandingPage";
const PageLoader = lazy(() => import("../../../includes/PageLoader"));
const Header = lazy(() => import("../../../includes/Header"));

function TefunRouter() {
    const { state } = useContext(Context);
    const {
        isStarted,
        isLoading,
        examStarted,
        courseCompleted,
        isStudentExists,
        examCompleted,
    } = state.tefunData;
    const {
        currentTopic,
    } = state;
    const [isTemp, setTemp] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setTemp(true);
        }, 700);
    });

    const navigate = useNavigate();

    return !isLoading && isTemp ? (
        <>
            <Header />
            <Routes>
                <Route>
                    <Route
                        path="/"
                        element={
                            isStarted ? (
                                <Navigate to="/technology-landing" />
                            ) : !isStarted && isStudentExists ? (
                                <Navigate to="/tefun/select-profile" />
                            ) : (
                                <Navigate to="/tefun/student-details" />
                            )
                        }
                    />
                    <Route path="explore" element={<LandingPage />} />
                
                    <Route
                        path="exam"
                        element={<Navigate to="/exam/start" />}
                    />

                    <Route
                        path="*"
                        element={
                            <Navigate
                                to={`/tefun/lessons/${state.tefunData.currentTopic}`}
                            />
                        }
                    />
                </Route>
            </Routes>
        </>
    ) : (
        <PageLoader />
    );
}

export default TefunRouter;
