import React, { useContext, useState } from "react";
import Header from "../../includes/Header";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import { Context } from "../../context/store";
import { useEffect } from "react";
import axios from "axios";
import { communityConfig } from "../../helpers/axiosConfig";
import { data } from "jquery";
import PageLoader from "../../includes/PageLoader";

function StartupSchoolLandingPage() {
    const { state, dispatch } = useContext(Context);
    const { tefunData, user_data } = state;
    const { access_token } = user_data;
    const [button, setButton] = useState("Start");
    const [courseData, setCourseData] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [isError, setError] = useState(false);
    const [isEnrolled, setIsEnrolled] = useState(false);
    const navigate = useNavigate();

    const { currentTopic } = state;
    useEffect(() => {
        fetchData();
    }, [isEnrolled]);

    const fetchData = () => {
        setLoading(true);
        communityConfig
            .get("courses/view-courses/", {
                headers: { Authorization: `Bearer ${access_token}` },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setCourseData(data);
                    setLoading(false);
                } else if (StatusCode === 6001) {
                    setError(true);
                }
            })
            .catch((error) => {
                setLoading(false);
                console.log(error);
            });
    };

    const handleCourse = (item) => {
        const headers = {
            Authorization: `Bearer ${access_token}`,
        };
        communityConfig
            .get(`/courses/course-lesson/${item}`, {
                headers,
            })
            .then((res) => {
                if (res.data.StatusCode == 6000) {
                    dispatch({
                        type: "UPDATE_TEFUN_DATA",
                        payload: {
                            selected_course: item,
                        },
                    });
                    localStorage.setItem("selected_course", item);
                    const data = res.data;
                    navigate(`/${item}/${data.current_topic}`, { state: item });
                }
            });
    };

    const enrollcourse = (item) => {
        const article = { title: "Enroll course " };
        const headers = {
            Authorization: `Bearer ${access_token}`,
        };
        communityConfig
            .post(`/courses/enroll-course/${item.slug}/`, article, {
                headers,
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setIsEnrolled(true);
                    navigate(`${item.slug}/${item.current_topic}/`);
                } else if (StatusCode === 6001) {
                    setError(true);
                }
            });

        handleCourse(item.slug);
    };

    const handleCompleted = (item) => {
        const headers = {
            Authorization: `Bearer ${access_token}`,
        };
        communityConfig
            .get(`/courses/course-lesson/${item.slug}`, {
                headers,
            })
            .then((res) => {
                if (res.data.StatusCode == 6000) {
                    dispatch({
                        type: "UPDATE_TEFUN_DATA",
                        payload: {
                            selected_course: item.slug,
                        },
                    });
                    localStorage.setItem("selected_course", item);

                    const data = res.data;
                    navigate(`/${item.slug}/${item.first_topic_pk}`, {
                        state: item,
                    });
                } else {
                    console.log(res);
                }
            });
    };

    const completed = (item) => {
        const headers = {
            Authorization: `Bearer ${access_token}`,
        };
        communityConfig
            .get(`/courses/course-lesson/${item.slug}`, {
                headers,
            })
            .then((res) => {
                if (res.data.StatusCode == 6000) {
                    dispatch({
                        type: "UPDATE_TEFUN_DATA",
                        payload: {
                            selected_course: item.slug,
                        },
                    });
                    localStorage.setItem("selected_course", item.slug);

                    const data = res.data;
                    navigate(`/${item.slug}/${item.first_topic_pk}`, {
                        state: item.slug,
                    });
                }
            });
    };

    return (
        <>
            {isLoading ? (
                <PageLoader />
            ) : (
                <>
                    <Header />
                    <MainContainer>
                        <section className="wrapper">
                            <Heading>
                                Select your desired <Span>Course</Span>
                            </Heading>
                            <CourseCards>
                                {courseData?.map((item) => (
                                    <CourseCard key={item.id}>
                                        {item.is_enrolled &&
                                        !item.is_course_completed ? (
                                            <ImageDiv
                                                onClick={() =>
                                                    handleCourse(item.slug)
                                                }
                                            >
                                                <ImageLink>
                                                    <img
                                                        src={item.thumbnail}
                                                        alt="Image"
                                                    />
                                                </ImageLink>
                                            </ImageDiv>
                                        ) : item.is_course_completed ? (
                                            <ImageDiv
                                                onClick={() => {
                                                    completed(item);
                                                }}
                                            >
                                                <ImageLink>
                                                    <img
                                                        src={item.thumbnail}
                                                        alt="Image"
                                                    />
                                                </ImageLink>
                                            </ImageDiv>
                                        ) : (
                                            <ImageDiv
                                                onClick={() => {
                                                    enrollcourse(item);
                                                    handleCourse(item.slug);
                                                }}
                                            >
                                                <ImageLink>
                                                    <img
                                                        src={item.thumbnail}
                                                        alt="Image"
                                                    />
                                                </ImageLink>
                                            </ImageDiv>
                                        )}

                                        {item.is_enrolled &&
                                        !item.is_course_completed ? (
                                            <StartButtonContinue
                                                onClick={() =>
                                                    handleCourse(item.slug)
                                                }
                                            >
                                                Continue
                                            </StartButtonContinue>
                                        ) : item.is_course_completed ? (
                                            <StartButtonLInk
                                                onClick={() => {
                                                    completed(item);
                                                }}
                                            >
                                                Learn
                                            </StartButtonLInk>
                                        ) : (
                                            <StartButton
                                                onClick={() => {
                                                    enrollcourse(item);
                                                    handleCourse(item.slug);
                                                }}
                                            >
                                                Start
                                            </StartButton>
                                        )}
                                    </CourseCard>
                                ))}
                            </CourseCards>
                        </section>
                    </MainContainer>
                </>
            )}
        </>
    );
}

export default StartupSchoolLandingPage;

const MainContainer = styled.div`
    padding: 100px 0 0 0;
    background-color: #fff;
    height: 100vh;
`;
const Heading = styled.p`
    font-size: 25px;
    @media (max-width: 480px) {
        font-size: 18px;
    }
    @media (max-width: 320px) {
        font-size: 16px;
    }
`;
const Span = styled.span`
    color: #0bbe61;
`;
const CourseCards = styled.div`
    /* display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 40px;
    @media (max-width: 1180px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
    @media (max-width: 640px) {
        display: grid;
        grid-template-columns: 1fr;
    } */
`;
const CourseCard = styled.div`
    width: 100%;
    text-align: center;
    position: relative;
`;
const StartButton = styled.div`
    cursor: pointer;
    background: linear-gradient(
        272deg,
        rgb(34, 193, 195) 0%,
        rgb(99, 187, 76) 0%,
        rgb(24, 152, 175) 100%
    );
    font-family: "gordita_medium";
    border-radius: 6px;
    width: 150px;
    margin: 0 auto;
    color: rgb(255, 255, 255);
    padding: 8px 10px;
    font-size: 17px;
    position: absolute;
    left: 60px;
    bottom: 45px;
    z-index: 1;
    @media (max-width: 1400px) {
        left: 55px;
        bottom: 45px;
    }
    @media (max-width: 1280px) {
        left: 50px;
        bottom: 35px;
    }
    @media (max-width: 1200px) {
        left: 48px;
        bottom: 30px;
        width: 130px;
        padding: 6px 10px;
    }
    @media (max-width: 1080px) {
        left: 42px;
        bottom: 30px;
        width: 110px;
        padding: 5px 10px;
    }
    @media (max-width: 980px) {
        left: 40px;
        bottom: 27px;
        width: 95px;
        padding: 5px 10px;
        font-size: 15px;
    }
    @media (max-width: 900px) {
        left: 32px;
        bottom: 20px;
        width: 95px;
        padding: 4px 10px;
        font-size: 14px;
    }
    @media (max-width: 768px) {
        width: 100px;
        padding: 7px;
        left: 0px;
        bottom: -35px;
    }
    @media (max-width: 640px) {
        width: 100px;
        padding: 6px;
        left: 0px;
        bottom: -35px;
    }
    @media (max-width: 480px) {
        width: 83px;
        padding: 5px;
        left: 0px;
        bottom: -31px;
        font-size: 13px;
    }
`;
const StartButtonContinue = styled.div`
    cursor: pointer;
    background: linear-gradient(
        272deg,
        rgb(34, 193, 195) 0%,
        rgb(99, 187, 76) 0%,
        rgb(24, 152, 175) 100%
    );
    font-family: "gordita_medium";
    border-radius: 6px;
    width: 150px;
    color: rgb(255, 255, 255);
    padding: 8px 10px;
    font-size: 16px;
    margin: 0 auto;
    position: absolute;
    left: 60px;
    bottom: 45px;
    z-index: 1;
    @media (max-width: 1400px) {
        left: 55px;
        bottom: 45px;
    }
    @media (max-width: 1280px) {
        left: 50px;
        bottom: 35px;
    }
    @media (max-width: 1200px) {
        left: 48px;
        bottom: 30px;
        width: 130px;
        padding: 6px 10px;
    }
    @media (max-width: 1080px) {
        left: 42px;
        bottom: 30px;
        width: 110px;
        padding: 5px 10px;
    }
    @media (max-width: 980px) {
        left: 40px;
        bottom: 27px;
        width: 95px;
        padding: 5px 10px;
        font-size: 15px;
    }
    @media (max-width: 900px) {
        left: 32px;
        bottom: 20px;
        width: 95px;
        padding: 4px 10px;
        font-size: 14px;
    }
    @media (max-width: 768px) {
        width: 100px;
        padding: 6px;
        left: 0px;
        bottom: -35px;
    }
    @media (max-width: 480px) {
        width: 83px;
        padding: 7px;
        left: 0px;
        bottom: -31px;
        font-size: 13px;
    }
`;
const StartButtonLInk = styled.div`
    cursor: pointer;
    background: linear-gradient(
        272deg,
        rgb(34, 193, 195) 0%,
        rgb(99, 187, 76) 0%,
        rgb(24, 152, 175) 100%
    );
    font-family: "gordita_medium";
    border-radius: 6px;
    width: 150px;
    margin: 0 auto;
    color: rgb(255, 255, 255);
    padding: 8px 10px;
    font-size: 17px;
    position: absolute;
    left: 60px;
    bottom: 45px;
    z-index: 1;
    @media (max-width: 1400px) {
        left: 55px;
        bottom: 45px;
    }
    @media (max-width: 1280px) {
        left: 50px;
        bottom: 35px;
    }
    @media (max-width: 1200px) {
        left: 48px;
        bottom: 30px;
        width: 130px;
        padding: 6px 10px;
    }
    @media (max-width: 1080px) {
        left: 42px;
        bottom: 30px;
        width: 110px;
        padding: 5px 10px;
    }
    @media (max-width: 980px) {
        left: 40px;
        bottom: 27px;
        width: 95px;
        padding: 5px 10px;
        font-size: 15px;
    }
    @media (max-width: 900px) {
        left: 32px;
        bottom: 20px;
        width: 95px;
        padding: 4px 10px;
        font-size: 14px;
    }
    @media (max-width: 768px) {
        width: 100px;
        padding: 7px;
        left: 0px;
        bottom: -35px;
    }
    @media (max-width: 480px) {
        width: 83px;
        padding: 5px;
        left: 0px;
        bottom: -31px;
        font-size: 13px;
    }
`;
const ImageDiv = styled.div`
    position: relative;
    cursor: pointer;
`;
const ImageLink = styled.div`
    margin-bottom: 30px;
    z-index: 1;
    /* position: absolute; */
    img {
        width: 100%;
        display: block;
        border-radius: 3px;
    }
`;
