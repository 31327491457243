import React from "react";
import styled from "styled-components";

export default function PlaceHolder({ paddingTop, isLoading }) {
    return (
        <Container
            style={{
                display: isLoading ? "block" : "none",
                paddingTop: isLoading ? paddingTop : "0",
            }}
        >
            <InnerContainer style={{ display: isLoading ? "flex" : "none" }}>
                <Logo
                    src={
                        require("../assets/images/allimages/Talrop-logo-vertical-color.png")
                            .default
                    }
                    alt="Logo"
                />
            </InnerContainer>
        </Container>
    );
}

const Container = styled.div`
    position: relative;
`;
const InnerContainer = styled.div`
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    background: #f3f3f3;
    top: 0;
    align-items: center;
    justify-content: center;
`;
const LogoImage = styled.img`
    display: block;
    width: 34%;
`;
const Logo = styled.img`
    display: block;
    width: 80px;
    &:after {
        content: "";
        position: absolute;
        top: -30%;
        right: -200%;
        width: 50%;
        height: 200%;
        opacity: 0;
        transform: rotate(30deg);
        background: rgba(255, 255, 255, 0.13);
        background: linear-gradient(
            to right,
            rgba(255, 255, 255, 0.13) 0%,
            rgba(255, 255, 255, 0.13) 77%,
            rgba(255, 255, 255, 0.5) 92%,
            rgba(255, 255, 255, 0) 100%
        );
        animation: shine 2s 1s;
        @keyframes shine {
            to {
                opacity: 1;
                right: 210%;
            }
        }
    }
`;
