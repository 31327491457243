const reducers = (state, action) => {
    switch (action.type) {
        case "UPDATE_USER_DATA":
            const user_data = { ...state.user_data, ...action.payload };
            localStorage.setItem("user_data", JSON.stringify(user_data));
            return {
                ...state,
                user_data: user_data,
            };
        case "UPDATE_CAMPUS":
            const campus_data = { ...state.campus_data, ...action.payload };
            return {
                ...state,
                campus_data: campus_data,
            };
        case "UPDATE_TEFUN_DATA":
            const tefunData = { ...state.tefunData, ...action.payload };
            return {
                ...state,
                tefunData: tefunData,
            };
        case "UPDATE_HEADER_HIDDEN":
            return {
                ...state,
                isHeaderHidden: action.value,
            };
        case "COUNTRY_SELECTED":
            const country_details = {
                ...state.country_details,
                ...action.country_details,
            };
            return {
                ...state,
                country_details: action.payload,
            };
        case "USER_CHECK_VALIDATE":
            const user_validate = {
                ...state.user_validate,
                ...action.user_validate,
            };
            localStorage.setItem(
                "user_validate",
                JSON.stringify(user_validate)
            );
            return {
                ...state,
                user_validate: user_validate,
            };
        case "UPDATE_STUDENTS":
            return {
                ...state,
                students: action.students,
            };
        case "UPDATE_CURRENT_VIDEO":
            localStorage.setItem("current_topic", action.currentTopic);
            return {
                ...state,
                currentTopic: action.currentTopic,
            };
        case "UPDATE_SEARCH_STUDENTS":
            return {
                ...state,
                search_results: action.search_results,
            };
        case "UPDATE_FILTER":
            return {
                ...state,
                isFilter: !state.isFilter,
            };
        case "UPDATE_ERROR":
            //  default state of error status
            let isError = action.isError ? action.isError : true;
            let status = 400;
            // if the there is a error message from components then the error message is show else default error message is shown
            let errorMessage = action.errorMessage
                ? action.errorMessage
                : "An error occured, please try later";
            // used to update the redirect link in case of redirect link available
            let redirectLink = action.redirectLink ? action.redirectLink : "";

            // if there is a responce in error state
            if (action.error.response) {
                status = action.error.response.status;
            }
            // updates the store
            return {
                ...state,
                errorState: {
                    isError: true,
                    errorData: {
                        status: status,
                        errorMessage: errorMessage,
                        redirectLink: redirectLink,
                    },
                },
            };
        case "COUNTRY_SELECT":
            return {
                ...state,
                country_code: action.country_code,
            };
        default:
            return state;
    }
};

export default reducers;
