import React, { useContext } from "react";
import styled from "styled-components";
import { Context } from "../../../../context/store";

export default function FlagDropDown(props) {
  const {
    state: {
      user_validate: { selected_country },
    },
  } = useContext(Context);
  return (
    // <Container >
    <Container onClick={props.handleClick}>
      <ImgContainer>
        {!selected_country.flag ? (
          <Temp></Temp>
        ) : (
          <Image src={selected_country.flag} alt="Image" />
        )}
      </ImgContainer>
      <Icon
        src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/auth/arrow.svg"
        alt="Icon"
      />
    </Container>
  );
}
const Container = styled.div`
  display: flex;
  margin: auto 0;
  cursor: pointer;
`;
const ImgContainer = styled.div`
  height: 42px;
  width: 42px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  margin-right: 7px;
  @media (max-width: 480px) {
    height: 36px;
    width: 36px;
  }
`;
const Temp = styled.div`
  height: 42px;
  width: 42px;
  border-radius: 50%;
  background: #e0e0e0;
`;
const Image = styled.img`
  display: block;
  object-fit: cover;
  width: 100%;
`;
const Icon = styled.img`
  display: block;
  width: 6px;
  transform: rotate(90deg);
  @media (max-width: 480px) {
    width: 5px;
  }
`;
