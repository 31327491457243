import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import html2canvas from "html2canvas";
import { Context } from "../context/store";
import { yiaaiConfig } from "../helpers/axiosConfig";
import { getDateStr } from "../helpers/functions";
import QRCode from "react-qr-code";
import { useSearchParams, useNavigate, Link } from "react-router-dom";
import PageLoader from "../includes/PageLoader";
// import imageToBase64 from "image-to-base64/browser";
import TalropEdtechHelmet from "../helpers/TalropEdtechHelmet";
import Header from "../includes/Header";
import Tefun404 from "./Tefun404";
import krea from "../../assets/images/krea.png"
import "../../assets/css/web/certificate.css";
import CertificateBg from "../../assets/images/certificate/certificate-background.svg";
import DottedLine from "../../assets/images/certificate/dotted-line.svg";

function TefunCertificate() {
    const { state } = useContext(Context);
    const user_data = state.user_data;
    const [certificateDetails, setCertificateDetails] = useState({});
    const [isLoading, setLoading] = useState(true);
    const [isError, setError] = useState(false);
    const [imageUrl, setImageUrl] = useState("");
    const [searchParams, setSearchParams] = useSearchParams();
    const certificateId = searchParams.get("id");
    const path = window.location.href;
    const navigate = useNavigate();

    // const download = function () {
    // 	let link = document.createElement("a");
    // 	const kebabCase = (string) =>
    // 		string
    // 			.replace(/([a-z])([A-Z])/g, "$1-$2")
    // 			.replace(/\s+/g, "-")
    // 			.toLowerCase();
    // 	link.download = `${
    // 		certificateDetails.name
    // 	}-${certificateId}-${kebabCase(
    // 		certificateDetails.free_course_title
    // 	)}-certificate.png`;
    // 	link.href = imageUrl;
    // 	link.click();
    // };

    const handleCopy = (elem_id) => {
        const elem = document.getElementById(elem_id);
        if (elem_id === "certificateURL")
            elem.parentElement.parentElement.classList.add("show");
        else elem.parentElement.parentElement.classList.add("show");
        elem.focus();
        elem.setSelectionRange(0, 99999);
        elem.select();
        document.execCommand("copy");
        setTimeout(function () {
            if (elem_id === "certificateURL")
                elem.parentElement.parentElement.classList.remove("show");
            else elem.parentElement.parentElement.classList.remove("show");
        }, 2000);
    };

    useEffect(() => {
        const generateLink = function () {
            html2canvas(document.getElementById(`certificate`), {
                scale: 8,
                useCORS: true,
                logging: true,
                ignoreElements: (element) => {
                    if (element.tagName === "svg") {
                        return false;
                    }
                    if (
                        element.tagName === "IMG" &&
                        !element.className.includes(`certificate-download-##`)
                    ) {
                        return true;
                    } else {
                        return false;
                    }
                },
            })
                .then((canvas) => {
                    const link = canvas.toDataURL();
                    setImageUrl(link);
                })
                .catch((error) => {
                    console.log(error, "err");
                });
        };

        if (!isLoading && Object.keys(certificateDetails).length > 0)
            generateLink();
    }, [isLoading, Object.keys(certificateDetails).length]);

    useEffect(() => {
        const fetchData = () => {
            setLoading(true);

            yiaaiConfig
                .get(`certifications/view-certificate/${certificateId}`)
                .then((response) => {
                    const { StatusCode, data } = response.data;
                    if (StatusCode === 6000) {
                        setLoading(false);
                        setCertificateDetails(data);
                    } else if (StatusCode === 6001) {
                        setLoading(false);
                        setError(true);
                    }
                })
                .catch((err) => {
                    console.log(err);
                    setLoading(false);
                });
        };

        fetchData();
    }, [certificateId]);

    const [buttonLoading, setButtonLoading] = useState(false);

    const DownloadCertificate = () => {
        const link = document.createElement("a");
        link.href = `${certificateDetails.certificate_url}`;
        link.setAttribute(
            "download",
            `${certificateDetails.free_course_title}-certificate.pdf`
        ); //or any other extension
        document.body.appendChild(link);
        link.click(setButtonLoading(true));
        setTimeout(() => {
            setButtonLoading(false);
        }, 5000);
    };
    console.log(certificateDetails,"-----certificateDetails-----");
    return (
        <div>
            <TalropEdtechHelmet title="certificate" />
            <Header />

            {isLoading ? (
                <LoaderContainer>
                    <PageLoader />
                </LoaderContainer>
            ) : !isLoading && isError ? (
                <Tefun404 isError={isError} />
            ) : (
                <>
                    <MainContainer>
                        <Container>
                            <LeftContainer>
                                <Heades>
                                    <MainHeading>Certificate</MainHeading>
                                    {/* <Issue>
								Issued <IssueDate>January</IssueDate>
							</Issue> */}
                                </Heades>
                                <Description>
                                    You've completed the program{" "}
                                    <span>
                                        {" "}
                                        {certificateDetails.free_course_title}
                                    </span>{" "}
                                    and have been verified by
                                    <span> Steyp </span> authorities.
                                </Description>
                                <CourseDetailsContainer>
                                    <CourseLeft>
                                        <CourseLabel>Course</CourseLabel>
                                        <CourseContent className="course-header">
                                            {
                                                certificateDetails.free_course_title
                                            }
                                        </CourseContent>
                                    </CourseLeft>
                                    <CourseRight>
                                        <CourseLeftContainer>
                                            <CourseLabel>Started</CourseLabel>
                                            <CourseContent>
                                                {certificateDetails.free_course_enrolled_date &&
                                                    getDateStr(
                                                        certificateDetails.free_course_enrolled_date
                                                    )}
                                            </CourseContent>
                                        </CourseLeftContainer>
                                        <CourseRightContainer>
                                            <CourseLabel>Completed</CourseLabel>
                                            <CourseContent>
                                                {certificateDetails.exam_start_date &&
                                                    getDateStr(
                                                        certificateDetails.exam_start_date
                                                    )}
                                            </CourseContent>
                                        </CourseRightContainer>
                                    </CourseRight>
                                </CourseDetailsContainer>
                                <IdContainer>
                                    <IdLabel>Certificate ID</IdLabel>
                                    <IdUrlContainer>
                                        <UrlInput>
                                            <UrlImage>
                                                <img
                                                    src={
                                                        require("../../assets/images/certificate/copy-id.svg")
                                                            .default
                                                    }
                                                    alt="Image"
                                                />
                                            </UrlImage>
                                            <Input
                                                id="certificateId"
                                                value={certificateId}
                                                readOnly
                                            />
                                        </UrlInput>
                                        <CopyButton
                                            onClick={() =>
                                                handleCopy("certificateId")
                                            }
                                        >
                                            <img
                                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/prime-programs/04-08-2021/copy_icon.svg "
                                                alt="Image"
                                            />
                                            <ButtonText>Copy ID</ButtonText>
                                            <ToolTipContainer x-placement="right">
                                                <ToolTip>Copied!</ToolTip>
                                            </ToolTipContainer>
                                        </CopyButton>
                                    </IdUrlContainer>
                                </IdContainer>
                                <IdContainer>
                                    <IdLabel>Certificate URL</IdLabel>
                                    <UrlContainer>
                                        <UrlInput>
                                            <UrlImage>
                                                <img
                                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/prime-programs/04-08-2021/Url_icon.svg
                                                "
                                                    alt="Image"
                                                />
                                            </UrlImage>
                                            <UrlText
                                                id="certificateURL"
                                                readOnly={true}
                                                type="url"
                                                value={window.location.href}
                                            />
                                        </UrlInput>
                                        <CopyButton
                                            onClick={() =>
                                                handleCopy("certificateURL")
                                            }
                                        >
                                            <img
                                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/prime-programs/04-08-2021/copy_icon.svg
                                            "
                                                alt="Image"
                                            />
                                            <ButtonText>Copy Link</ButtonText>
                                            <ToolTipContainer x-placement="right">
                                                <ToolTip>Copied!</ToolTip>
                                                parisienne{" "}
                                            </ToolTipContainer>
                                        </CopyButton>
                                    </UrlContainer>
                                </IdContainer>
                            </LeftContainer>
                            <RightContainer>
                                <Over>
                                    {/* <CertificateBackground> */}
                                    {/* <img
                                        src={require("../../assets/images/certificate/border.png")}
                                        alt="Image"
                                        /> */}

                                    {/* {imageUrl ? (
                                            <CoverBody className="first">
                                                <GenratedImage
                                                    src={imageUrl}
                                                    alt="Certificate"
                                                    // onLoad={setImageLoading(true)}
                                                />
                                            </CoverBody>
                                        ) : ( */}

                                    <CoverBody
                                        onmousedown="event.preventDefault ? event.preventDefault() : event.returnValue = false"
                                        id="certificate"
                                        className="certificate-download-##"
                                    >
                                        <TopCover>
                                            <TopLeft>
                                              {certificateDetails.campus_logo ? <SchoolLogo>
                                                    <img
                                                        src={
                                                            certificateDetails.campus_logo
                                                        }
                                                        alt="School Logo"
                                                    />
                                                </SchoolLogo>:null }
                                                <SchoolDetails>
                                                    <SchoolName>
                                                        {
                                                            certificateDetails.campus
                                                        }
                                                    </SchoolName>
                                                    <SchoolLocation>
                                                    {certificateDetails.panchayath}, {certificateDetails.district}
                                                    </SchoolLocation>
                                                </SchoolDetails>

                                            </TopLeft>
                                            <TopRight>
                                                <CertificateCode>
                                                    Certificate ID:{" "}
                                                    <span>
                                                        {
                                                            certificateDetails.certificate_id
                                                        }
                                                    </span>
                                                </CertificateCode>
                                                <CertificateDate>
                                                    Issued date{" "}
                                                    <span> 15/01/2022</span>
                                                </CertificateDate>
                                            </TopRight>
                                        </TopCover>
                                        <MiddleCover>
                                            <Heading>Certificate</Heading>
                                            <MiniHeading>
                                                OF APPRECIATION
                                            </MiniHeading>
                                            <BannerContainer>
                                                <img
                                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/yiaai/certification/23-07-2022/banner.svg"
                                                    alt="Banner"
                                                />
                                            </BannerContainer>
                                            <BannerContent>
                                                PROUDLY PRESENTED TO
                                            </BannerContent>
                                            <UserName>
                                                {certificateDetails.name}
                                            </UserName>
                                            <ProgramName>
                                                <small>completed </small> “
                                                <span>
                                                    {
                                                        certificateDetails.free_course_title
                                                    }
                                                </span>{" "}
                                                -{" "}
                                                {
                                                    certificateDetails.free_course_description
                                                }
                                                ”
                                            </ProgramName>
                                            <Performance>
                                                The performance was{" "}
                                                <span>
                                                    {certificateDetails.score}
                                                </span>{" "}
                                                Congratulations, All the very
                                                best!
                                            </Performance>
                                        </MiddleCover>
                                        <BottomCover>
                                            <BottomLeft>
                                                <ProjectFrom>
                                                    <span>A PROJECT FROM</span>
                                                    <div>
                                                        <img
                                                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/yiaai/certification/23-07-2022/Talrop-logo-vertical-color.png"
                                                            alt="Talrop Logo"
                                                        />
                                                    </div>
                                                </ProjectFrom>
                                                <CourseProvided>
                                                    <span>
                                                        COURSE PROVIDED BY
                                                    </span>
                                                    <div>
                                                        <img
                                                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/yiaai/certification/23-07-2022/Steyp - logo - no tag.png"
                                                            alt="Steyp Logo"
                                                        />
                                                    </div>
                                                </CourseProvided>
                                                {certificateDetails.media_partner ? <MediaPartner>
                                                    <span>MEDIA PARTNER</span>
                                                    <div>
                                                        <img
                                                            src={certificateDetails.media_partner}
                                                            alt="MEDIA PARTNER"
                                                        />
                                                    </div>
                                                </MediaPartner>:null}
                                                {certificateDetails.in_assosiated_with ?<Association>
                                                    <span>
                                                        IN ASSOCIATION WITH
                                                    </span>
                                                    <div>
                                                        <img
                                                            src={certificateDetails.in_assosiated_with}
                                                            alt="IN ASSOCIATION WITH"
                                                        />
                                                    </div>
                                                </Association>:null}
                                            </BottomLeft>
                                            <BottomRight>
                                                <SignContainer>
                                                    <Sign>
                                                        <img
                                                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/yiaai/certification/23-07-2022/sobir-signature.png"
                                                            alt="Sobir N"
                                                        />
                                                    </Sign>
                                                    <ProviderName>
                                                        Sobir N
                                                    </ProviderName>
                                                    <h6>CEO, Steyp</h6>
                                                </SignContainer>
                                                <QrCover>
                                                    <small>
                                                        Scan your Certificate
                                                    </small>
                                                    <QrContainer>
                                                        <img
                                                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/yiaai/certification/06-04-2022/qr.png"
                                                            alt="Qr Code"
                                                        />
                                                    </QrContainer>
                                                </QrCover>
                                            </BottomRight>
                                        </BottomCover>
                                    </CoverBody>
                                    {/* )} */}
                                    {/* </CertificateBackground> */}
                                </Over>

                                {imageUrl && !buttonLoading ? (
                                    <DownloadButton
                                        onClick={DownloadCertificate}
                                    >
                                        Download Certificate &nbsp;&nbsp;
                                        <img
                                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/yiaai/certification/26-02-2022/downloading.svg"
                                            alt="Image"
                                        />
                                    </DownloadButton>
                                ) : !imageUrl && !buttonLoading ? (
                                    <></>
                                ) : (
                                    <DownloadButton className="generate">
                                        Generating &nbsp;&nbsp;
                                    </DownloadButton>
                                )}
                            </RightContainer>
                        </Container>
                    </MainContainer>
                    {/* <Tefun404 /> */}
                </>
            )}
        </div>
    );
}

export default TefunCertificate;
const Url = styled.p`
    font-size: 35%;
    color: #3d5a17;
    margin-top: 1%;
    position: absolute;
    left: 5%;
    bottom: -2%;
`;
const LoaderContainer = styled.div`
    min-height: 700px;
    display: flex;
    align-items: center;
`;
const Over = styled.div`
    animation: fade 300ms ease-in-out 0ms;
    padding: 10px;
    box-shadow: 0 16px 24px rgb(0 0 0 / 10%);

    @media (max-width: 580px) {
        width: 400px;
        overflow-x: scroll;
    }
    @media (max-width: 480px) {
        width: 350px;
    }
    @media (max-width: 420px) {
        width: 340px;
    }
    @media (max-width: 390px) {
        width: 300px;
    }
    @media (max-width: 360px) {
        width: 260px;
    }
`;
const CoverBody = styled.div`
    text-align: center;
    z-index: 2;
    background-image: url("https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/yiaai/certification/23-07-2022/tefun-certificate-12.jpg");
    background-size: cover;
    position: relative;
    width: 492px;
    height: 343px;
    padding: 7% 15px 5% 25px;
    &.first {
        padding: 0px;
    }
`;
const TopCover = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
`;
const TopLeft = styled.div`
    display: flex;
    width: 43%;
    align-items: center;
`;
const SchoolLogo = styled.div`
    width: 27%;
    margin-right: 10px;
    img {
        width: 100%;
        display: block;
    }
`;
const SchoolDetails = styled.div`
    text-align: left;
    line-height: 14px;
`;
const SchoolName = styled.h3`
    font-size: 12px;
    text-align: left;
    font-family: "gordita_medium";
`;
const SchoolLocation = styled.span`
    font-size: 10px;
    text-align: left;
    display: inline-block;
`;
const TopRight = styled.div`
    width: 45%;
    text-align: right;
`;
const CertificateCode = styled.h3`
    font-size: 10px;
    font-family: "gordita_medium";
    span {
        display: inline-block;
        font-family: "gordita_regular";
        text-transform: uppercase;
    }
`;
const CertificateDate = styled.h3`
    font-size: 10px;
    font-family: "gordita_medium";
    span {
        font-family: "gordita_regular";
    }
`;
const MiddleCover = styled.div`
    position: relative;
`;
const Heading = styled.h3`
    font-family: "parisienne";
    font-size: 40px;
`;
const MiniHeading = styled.h5`
    font-family: "Merriweather";
    color: #4eb75d;
    font-size: 7.5px;
    width: 25%;
    position: absolute;
    left: 46%;
    top: 40px;
`;
const BannerContainer = styled.div`
    width: 25%;
    margin: 0 auto;
    margin-bottom: 5px;
`;
const BannerContent = styled.h3`
    font-size: 6.5px;
    font-family: "gordita_medium";
    color: #fff;
    position: absolute;
    top: 44%;
    left: 40.4%;
`;
const UserName = styled.h3`
    font-family: "gordita_medium";
    font-size: 12px;
    margin-bottom: 5px;
`;
const ProgramName = styled.div`
    font-family: "gordita_medium";
    font-size: 12px;
    margin-bottom: 5px;
    small {
        font-family: "gordita_regular";
    }
    span {
        color: #4eb75d;
    }
`;
const Performance = styled.h3`
    font-family: "gordita_regular";
    font-size: 9px;
    margin-bottom: 10px;
    span {
        font-family: "gordita_medium";
        background: #4eb75d;
        padding: 2px;
        color: #fff;
        border-radius: 0 2px;
        display: inline-block;
    }
`;
const BottomCover = styled.div`
    display: flex;
    justify-content: space-between;
`;
const BottomLeft = styled.div`
    width: 50%;
    display: flex;
    justify-content: left;
`;
const ProjectFrom = styled.div`
    width: 19%;
    margin-right: 20px;
    span {
        font-size: 4px;
    }
    div {
        width: 50%;
        margin: 0 auto;
        img {
            display: block;
            width: 100%;
        }
    }
`;
const CourseProvided = styled.div`
    width: 25%;
    margin-right: 20px;
    span {
        font-size: 4px;
    }
    div {
        width: 63%;
        margin: 0 auto;
        img {
            display: block;
            width: 100%;
        }
    }
`;
const MediaPartner = styled.div`
    width: 18%;
    margin-right: 20px;
    span {
        font-size: 4px;
    }
    div {
        width: 77%;
        margin: 0 auto;
        img {
            display: block;
            width: 100%;
        }
    }
`;
const Association = styled.div`
    width: 25%;
    margin-right: 20px;
    span {
        font-size: 4px;
    }
    div {
        width: 41%;
        margin: 0 auto;
        img {
            display: block;
            width: 100%;
        }
    }
`;
const BottomRight = styled.div`
    width: 25%;
    display: flex;
    align-items: center;
`;
const SignContainer = styled.div`
    h6 {
        font-size: 7px;
        margin-left: -32px;
    }
`;
const Sign = styled.div`
    width: 70%;
    img {
        width: 100%;
        display: block;
    }
`;
const ProviderName = styled.h5`
    font-size: 9px;
    font-family: "gordita_medium";
    margin-left: -32px;
`;
const QrCover = styled.div`
    small {
        font-size: 7px;
        display: inline-block;
    }
`;
const QrContainer = styled.div`
    width: 90%;
    img {
        width: 100%;
        display: block;
    }
`;

const MainContainer = styled.div`
    min-height: calc(100vh - 170px);
    margin: 60px 10px 25px 10px;
    background-color: #f9f9fb;
    border-radius: 5px;
    position: relative;
    @media (max-width: 768px) {
        margin: 55px 0 0 0;
    }
    @media (max-width: 590px) {
        padding: 15px;
    }
    @media (max-width: 480px) {
        padding: 10px;
    }
`;

const Container = styled.div`
    display: grid;
    grid-template-columns: 1.7fr 1fr;
    grid-gap: 30px;
    min-height: inherit;
    background-color: #fff;
    border-radius: 5px;
    padding: 35px;
    @media (max-width: 1280px) {
        padding: 35px 20px;
    }
    @media (max-width: 1170px) {
        grid-template-columns: 1fr;
        grid-gap: 0;
    }
    @media (max-width: 640px) {
        padding: 25px;
    }
    @media (max-width: 480px) {
        padding: 15px;
    }
`;
const LeftContainer = styled.div`
    display: flex;
    flex-direction: column;
    @media (max-width: 1170px) {
        width: 100%;
        margin-bottom: 50px;
        margin-right: 0;
    }
`;
const Heades = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 35px;
    @media (max-width: 480px) {
        margin-bottom: 20px;
    }
`;
const MainHeading = styled.h3`
    font-size: 24px;
    font-family: "gordita_medium";
    @media (max-width: 1200px) {
        font-size: 23px;
    }
    @media (max-width: 1170px) {
        font-size: 24px;
    }
    @media (max-width: 768px) {
        font-size: 22px;
    }
    @media (max-width: 480px) {
        font-size: 20px;
    }
`;
const Description = styled.p`
    font-size: 16px;
    text-align: left;
    color: #3f3f3f;
    font-family: "gordita_medium";
    margin-bottom: 15px;
    span {
        font-family: "gordita_medium";
    }
    @media (max-width: 1200px) {
        font-size: 15px;
    }
    @media (max-width: 1170px) {
        font-size: 16px;
    }
    @media (max-width: 768px) {
        font-size: 15px;
    }
    @media (max-width: 640px) {
        font-size: 14px;
    }
    @media (max-width: 480px) {
        font-size: 15px;
    }
`;
const CourseDetailsContainer = styled.div`
    border: 1px dashed #0fa7709a;
    background-color: #e8f6f1;
    border-radius: 5px;
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    @media (max-width: 640px) {
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }
`;
const CourseLeft = styled.div`
    display: flex;
    flex-direction: row;
    width: 46%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @media (max-width: 640px) {
        width: 100%;
    }
`;
const CourseRight = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 67%;
    @media (max-width: 1280px) {
        width: 73%;
    }
    @media (max-width: 640px) {
        width: 100%;
        justify-content: flex-start;
    }
    @media (max-width: 420px) {
        flex-direction: column;
        margin-top: 8px;
    }
    @media (max-width: 360px) {
        flex-direction: column;
    }
`;

const CourseLeftContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-right: 20px;
    align-items: baseline;
    @media (max-width: 1280px) {
        margin-right: 15px;
    }
`;

const CourseRightContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const CourseLabel = styled.p`
    margin-right: 6px;
    font-size: 16px;
    font-family: "gordita_medium";
    color: #3f3f3f;
    @media (max-width: 1200px) {
        font-size: 15px;
    }
    @media (max-width: 1170px) {
        font-size: 16px;
    }
    @media (max-width: 768px) {
        font-size: 15px;
    }
    @media (max-width: 670px) {
        font-size: 14px;
    }
    @media (max-width: 640px) {
        font-size: 13px;
    }
    @media (max-width: 480px) {
        font-size: 13px;
    }
`;
const CourseContent = styled.p`
    font-size: 15px;
    font-family: "gordita_medium";
    color: #0fa76f;
    font-style: italic;
    &.course-header {
        @media (max-width: 480px) {
            width: 100%;
        }
    }
    @media (max-width: 1200px) {
        font-size: 14px;
    }
    @media (max-width: 1170px) {
        font-size: 17px;
    }
    @media (max-width: 768px) {
        font-size: 16px;
    }
    @media (max-width: 670px) {
        font-size: 15px;
    }
    @media (max-width: 640px) {
        font-size: 14px;
    }
    @media (max-width: 480px) {
        font-size: 13px;
    }
`;

const IdContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;
`;
const IdLabel = styled.h6`
    font-size: 17px;
    font-family: "gordita_medium";
    margin-bottom: 10px;
    @media (max-width: 1200px) {
        font-size: 16px;
    }
    @media (max-width: 1170px) {
        font-size: 17px;
    }
    @media (max-width: 768px) {
        font-size: 16px;
    }
    @media (max-width: 640px) {
        font-size: 15px;
    }
    @media (max-width: 480px) {
        font-size: 15px;
    }
`;
const ToolTipContainer = styled.div`
    position: absolute;
    will-change: transform;
    top: 57px;
    transition: opacity 0.15s linear;
    opacity: 0;
    &:before {
        content: "";
        position: absolute;
        top: -2px;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        width: 0;
        height: 0;
        margin-top: -6px;
        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;
        border-right: 6px solid #000;
        transform: rotate(90deg);
    }
    @media (max-width: 480px) {
        top: 45px;
    }
`;
const IdUrlContainer = styled.div`
    display: grid;
    grid-template-columns: 3fr 1fr;
    grid-gap: 15px;
    @media (max-width: 580px) {
        display: flex;
        flex-direction: column;
        grid-gap: 10px;
    }
    &.show ${ToolTipContainer} {
        opacity: 0.9;
    }
`;
const Arrow = styled.div`
    position: absolute;
    display: block;
    left: 0;
    width: 0.4rem;
    height: 0.8rem;
    top: 7px;
    &:before {
        position: absolute;
        margin-top: -3px;
        content: "";
        border-width: 5px 5px 5px 0;
        border-right-color: #000;
    }
`;
const ToolTip = styled.div`
    position: relative;
    max-width: 200px;
    padding: 3px 8px;
    color: #fff;
    text-align: center;
    background-color: #000;
    border-radius: 0.25rem;
    @media (max-width: 480px) {
        font-size: 14px;
    }
`;
const Input = styled.input`
    width: 100%;
    border-radius: 5px;
    background-color: #f9f9fb;
    padding: 10px;
    color: #3f3f3f;
    font-size: 16px;
    font-family: "gordita_medium";
    @media (max-width: 1200px) {
        font-size: 15px;
    }
    @media (max-width: 1170px) {
        font-size: 16px;
    }
    @media (max-width: 768px) {
        font-size: 15px;
    }
    @media (max-width: 640px) {
        font-size: 14px;
    }
    @media (max-width: 480px) {
        font-size: 12px;
    }
    &:focus {
        color: #495057;
        border-color: #70f9b5;
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgb(9 225 118 / 25%);
    }
`;
const CopyButton = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0fa76f;
    padding: 8px 0;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    img {
        width: 15px;
    }
    @media (max-width: 480px) {
        img {
            width: 12px;
        }
    }
`;
const ButtonText = styled.div`
    color: #fff;
    font-size: 16px;
    font-family: "gordita_medium";
    margin-left: 5px;
    @media (max-width: 1200px) {
        font-size: 15px;
    }
    @media (max-width: 1170px) {
        font-size: 16px;
    }
    @media (max-width: 768px) {
        font-size: 15px;
    }
    @media (max-width: 640px) {
        font-size: 14px;
    }
    @media (max-width: 480px) {
        font-size: 12px;
    }
`;

const UrlContainer = styled.div`
    display: grid;
    grid-template-columns: 3fr 1fr;
    grid-gap: 15px;
    @media (max-width: 580px) {
        display: flex;
        flex-direction: column;
        grid-gap: 10px;
    }
    &.show ${ToolTipContainer} {
        opacity: 0.9;
    }
`;
const UrlInput = styled.div`
    display: flex;
    align-items: center;
    border: 1px solid #eaeaea;
    border-radius: 5px;
    background-color: #f9f9fb;
    color: #3f3f3f;
    font-size: 16px;
    font-family: "gordita_medium";
    @media (max-width: 1200px) {
        font-size: 15px;
    }
    @media (max-width: 1170px) {
        font-size: 16px;
    }
    @media (max-width: 768px) {
        font-size: 15px;
    }
    @media (max-width: 640px) {
        font-size: 14px;
    }
    @media (max-width: 480px) {
        font-size: 12px;
    }
`;

const UrlImage = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid #eaeaea;
    height: 100%;
    padding: 16px;
    border-radius: 5px;
    background-color: #fff;
    overflow: hidden;
    img {
        width: 15px;
    }
    @media (max-width: 480px) {
        padding: 14px;

        img {
            width: 12px;
        }
    }
`;

const UrlText = styled.input`
    width: 100%;
    border-radius: 5px;
    padding: 10px;
    background-color: #f9f9fb;
    color: #3f3f3f;
    font-size: 16px;
    font-family: "gordita_medium";
    @media (max-width: 1200px) {
        font-size: 15px;
    }
    @media (max-width: 1170px) {
        font-size: 16px;
    }
    @media (max-width: 768px) {
        font-size: 15px;
    }
    @media (max-width: 640px) {
        font-size: 14px;
    }
    @media (max-width: 480px) {
        font-size: 12px;
    }
    &:focus {
        color: #495057;
        border-color: #70f9b5;
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgb(9 225 118 / 25%);
    }
`;

const RightContainer = styled.div`
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    position: relative;
    align-items: center;
    @media (max-width: 1170px) {
        align-items: center;
    }
`;

const GenratedImage = styled.img`
    display: block;
    width: 100%;
    z-index: 999;
`;
const Certificate = styled.div`
    position: relative;
`;

const DownloadButton = styled.div`
    width: 235px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    text-align: center;
    font-size: 16px;
    color: #fff;
    background-color: #0fa76f;
    font-family: "gordita_medium";
    margin: 40px auto 0;
    cursor: pointer;
    padding: 12px 15px 7px;
    transition: 0.5s ease;
    animation: fade 300ms ease-in-out 0ms;

    &.generate {
        cursor: not-allowed;
        animation: fade 300ms ease-in-out 0ms;
    }
    @media (max-width: 1200px) {
        font-size: 15px;
    }
    @media (max-width: 1170px) {
        font-size: 16px;
    }
    @media (max-width: 768px) {
        font-size: 15px;
    }
    @media (max-width: 640px) {
        font-size: 12px;
    }
`;
