import React from "react";
import styled from "styled-components";
import { Link, useSearchParams } from "react-router-dom";
import Header from "../../includes/Header";
import ModalCover from "../../../general/login/ModalCover";

const SpotLight = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const action = searchParams.get("action");
    return (
        <>
            <Conatiner id="spotlight">
                <Header />
                <SectionWrapper className="wrapper">
                    <Title>
                        Building a Strong <b>Startup Ecosystem</b> in Kerala
                    </Title>
                    <Description>
                        Talrop is building a strong startup ecosystem in Kerala
                        and aims to transform Kerala into a Silicon Valley model
                        by 2030. Talrop has introduced various projects and
                        programs to fulfill this goal.
                    </Description>
                </SectionWrapper>
                <ImageContainer>
                    <img
                        src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/22-08-2022/Spot.png"
                        alt="Spotlight"
                    />
                </ImageContainer>
            </Conatiner>
            {action ? <ModalCover /> : null}
        </>
    );
};

export default SpotLight;

const Conatiner = styled.section`
    padding: 180px 0px 0px 0px;
    background-repeat: no-repeat;
    background: #f0f8ee;
    background-size: 100%;
    background-position: 0px 320px;
    min-height: 100vh;

    @media all and (max-width: 1280px) {
        background-position: 0px 65%;
    }
    @media all and (max-width: 980px) {
        background-position: 0px 70%;
        padding: 135px 0px 0px 0px;
    }
    @media all and (max-width: 768px) {
        padding: 125px 0 0px 0;
    }
    @media all and (max-width: 480px) {
        padding: 150px 0 20px 0;
    }
    @media all and (max-width: 360px) {
        padding: 130px 0 10px 0;
    }
    &.wrapper {
    }
`;
const SectionWrapper = styled.div`
    margin-bottom: 70px;
`;
const Title = styled.h1`
    font-size: 44px;
    font-family: gordita_medium;
    color: #212121;
    text-align: center;
    margin-bottom: 20px;

    @media all and (max-width: 1400px) {
        font-size: 48px;
    }
    @media all and (max-width: 1280px) {
        font-size: 44px;
    }
    @media all and (max-width: 768px) {
        font-size: 42px;
    }
    @media all and (max-width: 640px) {
        font-size: 34px;
    }
    @media all and (max-width: 480px) {
        font-size: 28px;
    }
    b {
        color: #0bbe61;
        font-family: gordita_bold;
    }
`;
const Description = styled.p`
    font-size: 16px;
    text-align: center;
    margin-bottom: 20px;
    width: 75%;
    margin: 0 auto;
    @media all and (max-width: 1280px) {
        width: 80%;
    }
    @media all and (max-width: 980px) {
        font-size: 17px;
    }
    @media all and (max-width: 768px) {
        font-size: 16px;
        width: 85%;
    }
    @media all and (max-width: 640px) {
        width: 90%;
    }
    @media all and (max-width: 480px) {
        width: 100%;
    }
    & br {
        @media all and (max-width: 1280px) {
            display: none;
        }
    }
`;
const Button = styled(Link)`
    background: #0fa76f;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 15px;
    width: 187px;
    font-size: 17px;
    color: #fff;
    font-family: "gordita_medium";
    margin: 30px auto 0 auto;
    z-index: 50;
    position: relative;
    /* position: absolute;
    z-index: 1;
    left: 0;
    top: 65px;
    right: 0; */
    cursor: pointer;
    @media all and (max-width: 1050px) {
        padding: 8px;
        width: 157px;
    }

    @media all and (max-width: 768px) {
        font-size: 15px;
    }
    @media all and (max-width: 640px) {
        margin-top: 20px;
        padding: 10px 8px;
    }
    @media all and (max-width: 480px) {
        font-size: 14px;
        width: 135px;
        padding: 8px 5px;
    }
    @media all and (max-width: 360px) {
        top: -12px;
    }
`;
const ImageContainer = styled.div`
    width: 100%;
    transform: translateY(-80px);
    position: relative;
    z-index: 1;
    @media all and (max-width: 1280px) {
        /* transform: translateY(-24px); */
    }
    @media all and (max-width: 1080px) {
        transform: translateY(-60px);
    }
    @media all and (max-width: 980px) {
        /* transform: translateY(-16px); */
    }
    @media all and (max-width: 768px) {
        /* transform: translateY(-3px); */
    }
    @media all and (max-width: 640px) {
        width: 100%;
        /* transform: translate3d(-65px, -29px, 10px); */
        /* transform: translateY(-55px); */
    }
    @media all and (max-width: 480px) {
        width: 100%;
        /* transform: translate3d(-55px, -22px, 10px); */
    }
    @media all and (max-width: 360px) {
        /* transform: translate3d(-43px, -22px, 10px); */
    }
    img {
        width: 100%;
        display: block;
    }
`;
