import React, { useContext } from "react";
import { Navigate,useLocation, useSearchParams } from "react-router-dom";
import { Context } from "../../context/store";

const PrivateRoute = ({ children }) => {
    const { state } = useContext(Context);
    const { is_verified } = state.user_data;
    const location = useLocation();

    // return is_verified ? children : <Navigate to="/tefun?action=login" />;
    const [searchParams,setSearchParams]=useSearchParams()
    return is_verified ? (
        children
    ) : (
        <Navigate
            to={ `auth`
            }
        />
    );
};

export default PrivateRoute;
