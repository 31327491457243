import React, { useState, useContext, useRef } from "react";
import styled from "styled-components";
import {
    Link,
    useLocation,
    useNavigate,
    useSearchParams,
} from "react-router-dom";
import { Context } from "../../../context/store";
import { accountsConfig } from "../../../helpers/axiosConfig";
import TefunLoader from "../../../tefun/TefunLoader";

function PasswordModal() {
    const recaptchaRef = useRef(null);
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const location = useLocation();
    const {
        state: {
            user_validate: { username },
        },
        dispatch,
    } = useContext(Context);

    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [isLoading, setLoading] = useState(false);
    const [hide, setHide] = useState(true);
    const [password, setPassword] = useState("");

    const handlePasswordShow = () => {
        setHide(!hide);
    };

    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            onSubmit();
        }
    };

    const onSubmit = (e) => {
        if (e) {
            e.preventDefault();
        }

        if (username) {
            if (password) {
                setLoading(true);
                accountsConfig
                    .post("/api/v1/accounts/login/login/", {
                        username: username,
                        password: password,
                    })
                    .then((res) => {
                        const {
                            StatusCode,
                            data: { message, response },
                        } = res.data;

                        if (StatusCode === 6000) {
                            dispatch({
                                type: "UPDATE_USER_DATA",
                                payload: {
                                    is_verified: true,
                                    // isStarted: true,
                                    access_token: res.data.data.access,
                                },
                            });
                            navigate("/technology-landing");
                            setLoading(false);
                        } else if (StatusCode === 6001) {
                            setLoading(false);
                            setError(true);
                            setErrorMessage(message);
                        }
                    })
                    .catch((error) => {
                        setLoading(false);
                        setError(true);
                        setErrorMessage(
                            "An error occurred, please try again later"
                        );
                    });
            } else {
                setError(true);
                setErrorMessage("This field cannot be blank");
            }
        } else {
            setError(true);
            setErrorMessage("user name not found");
        }
    };

    // const loginWithOtp = async () => {
    //     setLoading(true);
    //     const token = await recaptchaRef.current.executeAsync();
    //     accountsConfig
    //         .post("/api/v1/accounts/login/enter/otp/", {
    //             service: "learn",
    //             country: selected_country.web_code,
    //             phone: phone,
    //             "g-recaptcha-response": token,
    //         })
    //         .then((response) => {
    //             const {
    //                 StatusCode,
    //                 data: { message },
    //             } = response.data;
    //             if (StatusCode === 6000) {
    //                 setLoading(false);
    //                 setSearchParams({ action: "verify" });
    //             } else if (StatusCode === 6001) {
    //                 setLoading(false);
    //                 setError(true);
    //                 setErrorMessage(message);
    //             }
    //         })
    //         .catch((error) => {
    //             //Saved error message will be shown.
    //             setLoading(false);
    //             setError(true);
    //             setErrorMessage("An error occurred, please try again later");
    //         });
    // };

    return (
        <Container className="container">
            <JoinNow>
                <ItemContainer>
                    <>
                        <Content>
                            <Shape
                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/auth/shape.svg"
                                alt="Icon"
                            />
                            <Title className="g-medium">Password </Title>
                            <Description className="g-medium">
                                Enter your password for this account
                            </Description>
                            <InputContainer
                                style={{
                                    marginBottom: 0,
                                    borderColor: error && "#f46565",
                                }}
                            >
                                <Icon
                                    src={
                                        hide
                                            ? "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/auth/lock.svg"
                                            : "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/auth/unlock.svg"
                                    }
                                    alt="Icon"
                                />
                                <InputField
                                    className="g-medium"
                                    autoFocus
                                    style={{ paddingRight: 15 }}
                                    type={hide ? "password" : "text"}
                                    placeholder="Enter a password"
                                    onKeyDown={handleKeyDown}
                                    onChange={(e) => {
                                        setPassword(e.target.value);
                                        setError(false);
                                    }}
                                    value={password}
                                />
                                <Icon
                                    onClick={handlePasswordShow}
                                    style={{ cursor: "pointer" }}
                                    src={
                                        hide
                                            ? "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/auth/eye.svg"
                                            : "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/auth/hide.svg"
                                    }
                                    alt="Iocn"
                                />
                            </InputContainer>
                            <BottomRow
                                style={{
                                    justifyContent: error && "space-between",
                                }}
                            >
                                {error && (
                                    <ErrorText className="b-medium">
                                        {errorMessage}
                                    </ErrorText>
                                )}
                            </BottomRow>
                            <BottomButton
                                onClick={(e) => onSubmit(e)}
                                to="/"
                                className="g-medium white"
                            >
                                {isLoading ? <TefunLoader /> : "Login"}
                            </BottomButton>
                        </Content>
                    </>
                </ItemContainer>
            </JoinNow>
        </Container>
    );
}

export default PasswordModal;

const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 500;
`;
const CloseIcon = styled.span`
    font-size: 34px;
    color: #ff9800;
    position: absolute;
    left: -42px;
    top: 11px;
    cursor: pointer;
    @media (max-width: 480px) {
        font-size: 30px;
        left: -38px;
    }
    @media (max-width: 400px) {
        left: 9px;
        top: 11px;
        z-index: 500;
    }
`;
const JoinNow = styled.div`
    z-index: 500;
    background: #fff;
    width: 600px;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    animation: slide-box 0.4s ease;
    @keyframes slide-box {
        0% {
            right: -600px;
        }
        100% {
            right: 0;
        }
    }
    @media (max-width: 640px) {
        width: 428px;
    }
    @media (max-width: 480px) {
        width: 359px;
    }
    @media (max-width: 400px) {
        width: 100%;
    }
`;
const ItemContainer = styled.div`
    background: url("https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/auth/decorator.svg");
    background-repeat: no-repeat;
    background-size: contain;
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0 60px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media (max-width: 980px) {
        background-position-y: -106px;
    }
    @media (max-width: 640px) {
        padding: 0 29px;
        background-position-y: -79px;
    }
`;
const Content = styled.div`
    padding-top: 125px;
    height: -webkit-fill-available;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media (max-width: 640px) {
        padding-top: 52px;
    }
`;
const Title = styled.h4`
    font-size: 25px;
    line-height: 1.4em;
    margin-top: 14px;
    @media (max-width: 640px) {
        font-size: 23px;
        margin-top: 0;
    }
    @media (max-width: 480px) {
        font-size: 20px;
    }
`;
const Description = styled.p`
    margin: 15px 0;
    width: 94%;
    font: 14px;
    @media (max-width: 480px) {
        width: 100%;
        font-size: 13px;
    }
`;
const InputContainer = styled.div`
    position: relative;
    border: 1px solid;
    border-radius: 7px;
    padding: 15px 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #000;
    font-size: 17px;
    width: -webkit-fill-available;
    width: -moz-available;
    @media (max-width: 480px) {
        padding: 13px 13px;
        border-color: #5f6367;
        font-size: 15px;
    }
    &:focus-within {
        border-color: #5cc66a;
    }
`;
const Icon = styled.img`
    max-width: 17px;
    max-height: 17px;
    margin-right: 12px;
    display: block;
    @media (max-width: 480px) {
        display: block;
    }
`;
const InputField = styled.input`
    width: 100%;
    color: #000;
    font-size: 17px;
    padding-left: 15px;
    caret-color: #5cc66a;
    ::placeholder {
        font-size: 14px;
        font-family: "gordita_regular";
    }
    @media (max-width: 480px) {
        width: 106.666666667%;
        padding-left: 10.66667px;
        font-size: 13px;
        transform: scale(0.9375);
        transform-origin: left top;
    }
`;
const BottomRow = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 9px;
    font-size: 15px;
    @media (max-width: 640px) {
        margin: 10px 0 0 0;
    }
    @media (max-width: 480px) {
        font-size: 14px;
    }
`;
const RowItem = styled(Link)`
    font-size: 13px;
    margin-left: 7px;
    @media (max-width: 480px) {
        font-size: 12px;
    }
`;
const MiddleContainer = styled.div`
    display: flex;
    position: relative;
`;
const ErrorText = styled.span`
    font-size: 14px;
    left: 0;
    color: #f46565;
    bottom: -27px;
    @media (max-width: 480px) {
        font-size: 13px;
        bottom: -26px;
    }
`;
const BottomButton = styled(Link)`
    cursor: pointer;
    font-family: "gordita_medium";
    background: linear-gradient(
        272deg,
        rgba(34, 193, 195, 1) 0%,
        rgba(99, 187, 76, 1) 0%,
        rgba(24, 152, 175, 1) 100%
    );
    display: block;
    font-size: 18px;
    border-radius: 6px;
    height: 58px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
    color: #fff;
    min-height: 50px;
    @media (max-width: 480px) {
        height: 44px;
        font-size: 13px;
    }
`;
const Shape = styled.img`
    max-height: 29px;
    max-width: 29px;
    @media (max-width: 640px) {
        display: none;
    }
`;
const Forgot = styled(Link)`
    margin: 14px 0 70px;
    font-size: 14px;
    text-align: center;
    color: #5cc66a;
    @media (max-width: 640px) {
        margin-bottom: 0;
    }
    @media (max-width: 480px) {
        font-size: 13px;
    }
`;
