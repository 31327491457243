import React, { useContext } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Context } from "../../context/store";

import bg from "../../../assets/images/tefun/modal-back.svg";

const Modal = ({ isModal, setModal }) => {
    function handleClose() {
        setModal(false);
    }

    return (
        isModal && (
            <MainContainer>
                <Overlay onClick={() => handleClose()}></Overlay>

                <ModalContainer className="anim-fade">
                    <BgAnimation>
                        <PopperImg
                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/prime-programs/24-07-2021/dots.png"
                            alt="Image"
                        />
                    </BgAnimation>
                    <Left>
                        <ImageContainer>
                            <Image
                                src={
                                    "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/tefun/14-01-2022/course-completion.svg"
                                }
                                alt="Image"
                            />
                        </ImageContainer>
                    </Left>
                    <Right>
                        <Title>Congratulations!</Title>
                        <Para>You have successfully completed this course</Para>
                        <Button to="/dashboard">Go to Dashboard</Button>
                    </Right>
                    <BottomLine></BottomLine>
                    <BottomLine className="second"></BottomLine>
                </ModalContainer>
            </MainContainer>
        )
    );
};

export default Modal;

const BottomLine = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    bottom: 7px;
    height: 7px;
    background: linear-gradient(
        272deg,
        rgba(34, 193, 195, 1) 0%,
        rgba(99, 187, 76, 1) 0%,
        rgba(24, 152, 175, 1) 100%
    );
    &.second {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0px;
        height: 7px;
        background: linear-gradient(
            272deg,
            rgba(34, 193, 195, 1) 0%,
            rgba(99, 187, 76, 1) 0%,
            rgba(24, 152, 175, 1) 100%
        );
        border-bottom-right-radius: 6px;
        border-bottom-left-radius: 6px;
    }
`;

const MainContainer = styled.div`
    position: fixed;
    transition: 0.4s;
    width: 100%;
    height: 100vh;
    z-index: 1000;
    left: 0;
    top: 0;
    display: grid;
    place-items: center;
`;
const BgAnimation = styled.div`
    z-index: 1;
    position: absolute;
    top: -38%;
    left: -2%;
    width: 102%;
    animation: animate 3s;
    animation-fill-mode: forwards;
    @keyframes animate {
        from {
            transform: scale(0);
        }
        to {
            transform: scale(1);
        }
    }
    @media (max-width: 840px) {
        top: -28%;
    }
    @media (max-width: 768px) {
        top: -2%;
    }
`;
const PopperImg = styled.img`
    width: 100%;
    display: block;
`;
const Overlay = styled.div`
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100vh;
    cursor: pointer;
`;
const ModalContainer = styled.div`
    position: absolute;
    width: 35%;
    height: 380px;
    border-radius: 9px;
    background-image: url(${bg});
    background-color: #fff;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 35px;
    @media (max-width: 1800px) {
        width: 40%;
    }
    @media (max-width: 1600px) {
        width: 45%;
    }
    @media (max-width: 1440px) {
        width: 50%;
    }
    @media (max-width: 1280px) {
        width: 60%;
    }
    @media (max-width: 1050px) {
        width: 65%;
    }
    @media (max-width: 980px) {
        height: auto;
        width: 60%;
    }
    @media (max-width: 840px) {
        width: 65%;
        height: auto;
    }
    @media (max-width: 768px) {
        flex-direction: column;
    }
    @media (max-width: 640px) {
        padding: 25px;
    }
    @media (max-width: 480px) {
        width: 80%;
    }
    @media (max-width: 360px) {
        width: 90%;
    }
`;

const Left = styled.div`
    z-index: 10;
`;
const ImageContainer = styled.div`
    width: 433px;
    top: 30px;
    position: absolute;
    left: -37px;
    z-index: 1;
    @media (max-width: 1080px) {
        width: 370px;
        top: 84px;
        left: -53px;
    }

    @media (max-width: 980px) {
        width: 300px;
        top: 58px;
    }
    @media (max-width: 768px) {
        width: 272px;
        top: 7px;
        left: -7px;
        position: relative;
    }
    @media (max-width: 480px) {
        width: 272px;
    }
`;
const Image = styled.img`
    width: 100%;
    display: block;
`;
const Right = styled.div`
    width: 50%;
    text-align: center;
    z-index: 10;
    @media (max-width: 980px) {
        width: 50%;
    }
    @media (max-width: 768px) {
        width: 100%;
        margin-top: 23px;
    }
`;
const Title = styled.h3`
    color: #0fa76f;
    font-size: 25px;
    font-family: "gordita_bold";
    margin-bottom: 10px;
    @media (max-width: 1440px) {
        font-size: 25px;
    }
    @media (max-width: 980px) {
        font-size: 23px;
    }
    @media (max-width: 480px) {
        font-size: 20px;
    }
`;
const Para = styled.p`
    color: #18484c;
    margin-bottom: 40px;
    font-size: 16px;
    line-height: 1.3em;
    font-family: "gordita_medium";
    @media (max-width: 1440px) {
        font-size: 16px;
    }
    @media (max-width: 980px) {
        font-size: 15px;
    }

    @media (max-width: 498px) {
        margin: 12px 0 20px;
    }
`;
const Button = styled(Link)`
    margin: 0 auto;
    width: 75%;
    color: #fff;
    padding: 10px 16px;
    background: linear-gradient(
        272deg,
        rgba(34, 193, 195, 1) 0%,
        rgba(99, 187, 76, 1) 0%,
        rgba(24, 152, 175, 1) 100%
    );
    display: block;
    border-radius: 5px;
    font-size: 15px;
    font-family: "gordita_medium";
    margin-bottom: 15px;
    cursor: pointer;
    opacity: 1;
    &.hide {
        visibility: hidden;
        cursor: none;
    }
    &:hover {
        background-color: #1aaa75;
        opacity: 1;
    }
    @media (max-width: 980px) {
        font-size: 15px;
    }
    @media (max-width: 380px) {
        font-size: 14px;
        padding: 8px 10px;
    }
`;
